
export default class EnvironmentService
{
    static devHostname : string = "localhost:4000";
    static prodHostname : string = "lifeanizer.com";

    public static IsProduction()
    {
        return EnvironmentService.prodHostname === window.location.host;
    }

    public static GetHostname() : string
    {
        if(EnvironmentService.IsProduction())
        {
            return EnvironmentService.prodHostname;
        }
        else{
            return EnvironmentService.devHostname;
        }
    }
}