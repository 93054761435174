import React, { useState } from "react";
import NotebookService from "../services/notebookService";
import IDService from "../services/idService";
import NotebookMetadata from "../models/notebookMetadata";
import ActiveNotebook from "./activeNotebook";
import DateService from "../services/dateService";
import AvailableNotebook from "../models/availableNotebook";

class NotebookPickerProps {
  setNotes: Function | null = null;
  setActiveNotebook: Function | null = null;
}

function NotebookPicker(props: NotebookPickerProps) {
  const [notebooks, setNotebooks] = useState<Array<AvailableNotebook>>([]);
  const [activeNotebook, setActiveNotebook] = useState<NotebookMetadata | null>(null);

  if (notebooks.length === 0) {
    const notebookService = new NotebookService();
    notebookService.getNotebooks().then((data) => {
      setNotebooks(data);
    });
    
  }

  function updateActiveNotebook(notebook: AvailableNotebook){
    const notebookService = new NotebookService();
    if(notebook.existsInOPFS)
    {
      notebookService.openNotebook(notebook.OPFSPath).then((activeNotebook) => {
        if(!activeNotebook)
        {
          return;
        }
        setActiveNotebook(activeNotebook);
        if(props.setActiveNotebook)
        {
          props.setActiveNotebook(activeNotebook);
        }
        const notebookService = new NotebookService();
        notebookService.getNotes().then((notes) => {
          if(props.setNotes)
          {
              props.setNotes(notes);
          }
        });
      });
    }
    else{
      notebookService.downloadDropboxNotebook(notebook.dropboxPath).then(() => {
        notebookService.openNotebook(notebook.dropboxPath).then((activeNotebook) => {
          if(!activeNotebook)
          {
            return;
          }
          setActiveNotebook(activeNotebook);
          if(props.setActiveNotebook)
          {
            props.setActiveNotebook(activeNotebook);
          }
          const notebookService = new NotebookService();
          notebookService.getNotes().then((notes) => {
            if(props.setNotes)
            {
                props.setNotes(notes);
            }
          });
        });
      });
    }
  }

  const createNewNotebook = () => {
    const newNotebook = new NotebookMetadata();
    newNotebook.id = IDService.generateId();
    newNotebook.name = DateService.getDateString() + " Notebook";

    const notebookService = new NotebookService();
    notebookService.createNotebook(newNotebook);
    NotebookService.activeNotebook = newNotebook;
    notebookService.getNotebooks().then((data) => {
      setNotebooks(data);
    });
  }

  const deleteNotebook = (notebook: AvailableNotebook) => {
    const notebookService = new NotebookService();
    notebookService.deleteNotebook(notebook.OPFSPath);
    notebookService.getNotebooks().then((data) => {
      setNotebooks(data);
    });
  }

  const notebookList = notebooks
    ? notebooks.map((notebook) => {
        return (
          <div key={notebook.metadata.id}>
            <button
              onClick={() => deleteNotebook(notebook)}>
                X
            </button>
            <button
              onClick={() => {
                updateActiveNotebook(notebook);
              }}
            >
              {notebook.metadata.name}
            </button>
            <br />
          </div>
        );
      })
    : "";

  return activeNotebook == null ? (
    <div>
        <p>Notebooks:</p>
          {notebookList}
        <button onClick={createNewNotebook}>New Notebook</button>
    </div>
  ) : (
    <ActiveNotebook notebook={activeNotebook} />
  );
}

export default NotebookPicker;
