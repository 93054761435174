import Note from "../models/note";
import AutocompletePicker from "./ui/autocompletePicker";

class NoteSidebarProps
{
    notes : Array<Note> | null = null;
}

export default function NoteSidebar(props: NoteSidebarProps)
{
    function onSelect(selectedOption : string)
    {
        console.log(selectedOption);
    }

    const options : Array<[number, string]> = [];
    props.notes?.forEach((note) => {
        options.push([note.id, note.title]);
    });

    return (
        <div>
            <AutocompletePicker options={options} onSelect={onSelect}></AutocompletePicker>
        </div>
    )
}