import AuditEvent from "./auditEvent";

export default class Note{
    id: number = 0;
    title: string = "";
    body: string = "";
    tags: Array<number> = [];
    noteLinks: Array<number> = [];
    isSensitive: boolean = false;
    createdAt: Date = new Date();
    updatedAt: Date = new Date();
    history: Array<AuditEvent> = [];
}