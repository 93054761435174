import React from "react";
import NotebookService from "../services/notebookService";
import IDService from "../services/idService";
import DateService from "../services/dateService";
import Note from "../models/note";

class NoteListProps {
  notes : Array<Note> | null = null;
  setNotes: Function = () => {};
  setActiveNote: Function = () => {};
}

function NoteList(props: NoteListProps) {
  const createNewFile = () => {
    const notebookService = new NotebookService();
    const newNote = new Note();
    newNote.id = IDService.generateId();
    newNote.title = DateService.getDateString() + " Note";
    newNote.body = "Type your note here.";
    notebookService.createNote(newNote);
    notebookService.getNotes().then((notes) => {
      if(props.setNotes)
      {
          props.setNotes(notes);
      }
    });
    props.setActiveNote(newNote);
  }

  const deleteNote = (note : Note) => {
    const notebookService = new NotebookService();
    notebookService.deleteNote(note);
    notebookService.getNotes().then((notes) => {
      if(props.setNotes)
      {
          props.setNotes(notes);
      }
    });
  }

  const noteList = props.notes != null ? props.notes.map((note) => {
    return (
    <div key={note.id}>
      <button onClick={() => {deleteNote(note);}}>X</button>
      <button onClick={() => {props.setActiveNote(note);}} >{note.title}</button>
      <br />
    </div>);
  }) : "";

  return (
    <div>
      <p>Files:</p>
      {noteList}
      <button onClick={createNewFile}>New Note</button>
    </div>
  );
}

export default NoteList;
