import React, { useState } from "react";

class AutocompletePickerProps
{
    options: Array<[number, string]> = [];
    onSelect: Function | null = null;
}

export default function AutocompletePicker(props: AutocompletePickerProps)
{
    const [searchText, setSearchText] = useState<string>("");
    const [matchingOptions, setMatchingOptions] = useState<Array<[number, string]>>([]);

    function updateSearch(changeEvent: React.ChangeEvent<HTMLInputElement>) {
        setSearchText(changeEvent.target.value);
        const newOptions : Array<[number, string]> = [];
        if(changeEvent.target.value !== "")
        {
            props.options.forEach((option) =>{
                if(option[1].indexOf(changeEvent.target.value) >= 0)
                {
                    newOptions.push(option);
                }
            })
        }
        setMatchingOptions(newOptions);
    };

    function selectOption(option : number)
    {
        setSearchText("");
        setMatchingOptions([]);
        if(props.onSelect)
        {
            props.onSelect(option);
        }
    }

    const optionList = matchingOptions ? matchingOptions.map((option) => {
        return (
        <div key={option[0]} onClick={() => { return selectOption(option[0]) }}>
          {option[1]}
        </div>);
      }) : "";

    return (
        <div>
            <input type="text" value={searchText} onChange={updateSearch} />
            {optionList}
        </div>
    )
}