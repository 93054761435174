import React, { useState } from "react";
import NotebookMetadata from "../models/notebookMetadata";
import NotebookService from "../services/notebookService";

class ActiveNotebookProps {
    notebook: NotebookMetadata | null = null;
}

function ActiveNotebook(props: ActiveNotebookProps) {
    const [dropboxSync, setDropboxSync] = useState<boolean>(props.notebook?.syncsToDropbox === true);
    const [notebookName, setNotebookName] = useState<string>(props.notebook?.name ?? "");
    const [isEditingNotebookName, setIsEditingNotebookName] = useState<boolean>(false);

    function toggleDropboxSync() {
        if (!props.notebook) return;
        props.notebook.syncsToDropbox = !props.notebook.syncsToDropbox;
        setDropboxSync(props.notebook.syncsToDropbox);
        const notebookService = new NotebookService();
        notebookService.createNotebook(props.notebook);
    }

    function updateNotebookName(event: React.ChangeEvent<HTMLInputElement>) {
        setNotebookName(event.target.value);
    }

    function beginEditing() {
        setIsEditingNotebookName(true);
    }

    function saveNotebook() {
        if(!props.notebook) return;
        const notebookService = new NotebookService();
        props.notebook.name = notebookName;
        notebookService.createNotebook(props.notebook);
        setIsEditingNotebookName(false);
    }

    const toggleText = dropboxSync ? "Disable Dropbox Sync"
        : "Enable Dropbox Sync";

    return (
        <div>
            {isEditingNotebookName ?
            <div><input type="text" value={notebookName} onChange={updateNotebookName} /><button onClick={saveNotebook}>Save</button></div>
            : <p onClick={beginEditing}>{props.notebook?.name}</p> }
            <button onClick={toggleDropboxSync}>{toggleText}</button>
        </div>
    );
}

export default ActiveNotebook;
