import AvailableNotebook from "../models/availableNotebook";
import listFolderResponseEntry from "../models/dropbox/listFolderResponseEntry";
import Note from "../models/note";
import NotebookMetadata from "../models/notebookMetadata";
import DropboxService from "./dropboxService";
import OPFSService from "./opfsService";

export default class NotebookService {
    public static activeNotebook: NotebookMetadata | null = null;
    public static setActiveNotebook(notebook: NotebookMetadata) {
        NotebookService.activeNotebook = notebook;
    }

    private opfsService: OPFSService;
    private dropboxService: DropboxService;

    constructor(
        opfsService: OPFSService | null = null,
        dropboxService: DropboxService | null = null
    ) {
        this.opfsService = opfsService ?? new OPFSService();
        this.dropboxService = dropboxService ?? new DropboxService();
    }

    public async createNotebook(notebook: NotebookMetadata): Promise<void> {
        const filePath = "/Notebook-" + notebook.id + "/meta.json";
        this.opfsService.createFile(filePath, JSON.stringify(notebook));
        if (this.dropboxService.isConnected() && notebook.syncsToDropbox) {
            this.dropboxService.saveFile(filePath, JSON.stringify(notebook));
        }
    }

    public async deleteNotebook(path: string): Promise<void> {
        const folder = path.split("/")[0];
        this.opfsService.deleteDirectoryFromRoot(folder);
        // TODO: also delete from Dropbox
    }

    public async createNote(note: Note): Promise<void> {
        if (NotebookService.activeNotebook === null) {
            return;
        }
        const filePath =
            "/Notebook-" +
            NotebookService.activeNotebook.id +
            "/Notes/Note-" +
            note.id +
            ".json";
        this.opfsService.createFile(filePath, JSON.stringify(note));
        if (
            this.dropboxService.isConnected() &&
            NotebookService.activeNotebook.syncsToDropbox
        ) {
            this.dropboxService.saveFile(filePath, JSON.stringify(note));
        }
    }

    public async deleteNote(note: Note): Promise<void> {
        if (NotebookService.activeNotebook === null) {
            return;
        }
        const filePath =
            "/Notebook-" +
            NotebookService.activeNotebook.id +
            "/Notes/Note-" +
            note.id +
            ".json";
        this.opfsService.deleteFile(filePath);
    }

    public async openNotebook(path: string): Promise<NotebookMetadata> {
        return await this.opfsService.readFile(path).then((data) => {
            if (!data) {
                return;
            }
            const parsedNotebook = JSON.parse(data);
            NotebookService.activeNotebook = parsedNotebook;
            return parsedNotebook;
        });
    }

    public async downloadDropboxNotebook(path: string): Promise<void> {
        const dropboxFolder = path.substring(0, path.lastIndexOf("/"));
        await this.dropboxService.listFilesInFolder(dropboxFolder).then(async (data) => {
            if (!data) {
                return;
            }
            for(let entry of data.entries)
            {
                if(entry[".tag"] === "folder")
                {
                    continue;
                }
                await this.dropboxService.getFile(entry.path_display).then(async (dropboxFile) => {
                    await this.opfsService.createFile(entry.path_display, dropboxFile);
                });
            }
        });
    }

    public async getNotebooks(): Promise<AvailableNotebook[]> {
        const opfsNotebooks = await this.opfsService.listNotebooks();
        const availableNotebooks : AvailableNotebook[] = opfsNotebooks;
        const dropboxNotebooks = await this.dropboxService.getNotebooks();
        dropboxNotebooks.forEach((dropboxNotebook) => {
            if(!opfsNotebooks.some(
                (opfsNotebook) => {
                    if(opfsNotebook.metadata.id === dropboxNotebook.metadata.id)
                    {
                        opfsNotebook.dropboxPath = dropboxNotebook.dropboxPath;
                        return true;
                    }
                })
            )
            {
                dropboxNotebook.existsInOPFS = false;
                availableNotebooks.push(dropboxNotebook);
            }
        });
        return availableNotebooks;
    }

    protected _notes: Array<Note> | null = null;

    public async getNotes(): Promise<Array<Note>> {
        if (NotebookService.activeNotebook === null) {
            return [];
        }
        if (this._notes !== null) {
            return this._notes;
        }
        this._notes = [];
        const files = await this.opfsService.listFilesInFolder(
            "/Notebook-" + NotebookService.activeNotebook.id + "/Notes"
        );
        for (let file of files) {
            const contents = await this.opfsService.readFile(
                "/Notebook-" + NotebookService.activeNotebook.id + "/Notes/" + file
            );
            try {
                const note = JSON.parse(contents);
                this._notes.push(note);
            } catch (e) {
                console.log("Failed to parse note", e);
                continue;
            }
        }
        return this._notes;
    }
}
