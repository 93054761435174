import AvailableNotebook from "../models/availableNotebook";
import OPFSEntity from "../models/opfsEntity";

export default class OPFSService
{
    public async listFilesInFolder(folderPath: string) : Promise<string[]>
    {
        const dirHandle = await this.getDirectoryHandleFromPath(folderPath);
        const values = await dirHandle.values();
        const files : string[] = [];
        for await (const value of values) {
            if(value.kind === "file")
            {
                files.push(value.name);
            }
        }
        return files;
    }

    public async listFoldersInFolder(folderPath: string) : Promise<string[]>
    {
        const dirHandle = await this.getDirectoryHandleFromPath(folderPath);
        const values = await dirHandle.values();
        const files : string[] = [];
        for await (const value of values) {
            if(value.kind === "directory")
            {
                files.push(value.name);
            }
        }
        return files;
    }

    public async listNotebooks() : Promise<AvailableNotebook[]>
    {
        const folders = await this.listFoldersInFolder("");
        const notebooks : AvailableNotebook[] = [];
        for(let folder of folders)
        {
            const dirHandle = await this.getDirectoryHandleFromPath(folder);
            const values = await dirHandle.values();
            for await (const value of values) {
                if(value.kind === "file" && value.name === 'meta.json')
                {
                    const filePath = folder + '/' + value.name;
                    const contents = await this.readFile(filePath);
                    try{
                        const notebook = JSON.parse(contents);
                        const entity = new AvailableNotebook();
                        entity.existsInOPFS = true;
                        entity.OPFSPath = filePath;
                        entity.metadata = notebook;
                        notebooks.push(entity);
                    } catch(e)
                    {
                        console.log("Failed to parse meta.json", e);
                        continue;
                    }
                }
            }
        }
        return notebooks;
    }

    private async getDirectoryHandleFromPath(path: string) : Promise<FileSystemDirectoryHandle>
    {
        const pathParts = path.split("/");
        let dirHandle = await navigator.storage.getDirectory();
        for(const pathPart of pathParts)
        {
            if(pathPart === "")
            {
                continue;
            }
            dirHandle = await dirHandle.getDirectoryHandle(pathPart, {create: true});
        }
        return dirHandle;
    }

    public async createDirectory(directoryName: string)
    {
        await navigator.storage.getDirectory().then((dir) => {
            dir.getDirectoryHandle(directoryName, {create: true}).then((dirHandle) => {
                console.log("Created directory", dirHandle);
            });
        });
    }

    public async deleteDirectoryFromRoot(directoryName: string)
    {
        await navigator.storage.getDirectory().then((dir) => {
            dir.removeEntry(directoryName, {recursive: true}).then(() => {
                console.log("Deleted directory", directoryName);
            });
        });
    }

    public async createFile(filePath: string, contents : string|null = null) : Promise<void>
    {
        const directory = this.getPathFromFilePath(filePath);
        const fileName = this.getFilenameFromFilePath(filePath);
        const dirHandle = await this.getDirectoryHandleFromPath(directory);
        const fileHandle = await dirHandle.getFileHandle(fileName, {create: true});
        if(contents)
        {
            const writer = await fileHandle.createWritable();
            writer.write(contents);
            await writer.close();
        }
    }

    public async readFile(filePath: string) : Promise<string>
    {
        const directory = this.getPathFromFilePath(filePath);
        const fileName = this.getFilenameFromFilePath(filePath);
        const dirHandle = await this.getDirectoryHandleFromPath(directory);
        const fileHandle = await dirHandle.getFileHandle(fileName);
        const file = await fileHandle.getFile();
        const text = await  file.text();
        return text;
    }

    public async deleteFile(filePath: string) : Promise<void>
    {
        const directory = this.getPathFromFilePath(filePath);
        const fileName = this.getFilenameFromFilePath(filePath);
        const dirHandle = await this.getDirectoryHandleFromPath(directory);
        dirHandle.removeEntry(fileName);
    }

    private getPathFromFilePath(filePath: string) : string
    {
        const lastSlash = filePath.lastIndexOf("/");
        const directory = filePath.substring(0, lastSlash);
        return directory;
    }

    private getFilenameFromFilePath(filePath: string) : string
    {
        const lastSlash = filePath.lastIndexOf("/");
        const fileName = filePath.substring(lastSlash + 1);
        return fileName;
    }
}