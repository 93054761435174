import AvailableNotebook from '../models/availableNotebook';
import listFolderResponse from '../models/dropbox/listFolderResponse';
import EnvironmentService from './environmentService';

export default class DropboxService {
  static isLoadingAuth: boolean = false;
  static devAppKey: string = "3g43fnl583a7sol";
  static devRedirectHostname: string = "https://localhost:4000/";
  static prodAppKey: string = "enrod6yitip4mpm";
  static prodRedirectHostname: string = "https://lifeanizer.com/";

  public static getSSOLink(encodedHash: string): string {
    const dropboxPath = "https://www.dropbox.com/oauth2/authorize";
    const codeChallengeParams = `code_challenge=${encodedHash}&code_challenge_method=S256&response_type=code`;
    if (!EnvironmentService.IsProduction()) {
      return `${dropboxPath}?client_id=${DropboxService.devAppKey}&${codeChallengeParams}&redirect_uri=${DropboxService.devRedirectHostname}`;
    }
    else {
      return `${dropboxPath}?client_id=${DropboxService.prodAppKey}&${codeChallengeParams}&redirect_uri=${DropboxService.prodRedirectHostname}`;
    }
  }

  public static getClientId(): string {
    if (!EnvironmentService.IsProduction()) {
      return DropboxService.devAppKey;
    }
    else {
      return DropboxService.prodAppKey;
    }
  }

  public static getRedirectUri(): string {
    return "https://" + EnvironmentService.GetHostname() + "/";
  }

  public static accessToken: string | null = null;

  public saveFile(filename: string, contents: string) {
    let xhr2 = new XMLHttpRequest();
    xhr2.open("POST", "https://content.dropboxapi.com/2/files/upload");
    xhr2.setRequestHeader("Authorization", "Bearer " + DropboxService.accessToken);
    xhr2.setRequestHeader("Dropbox-API-Arg", `{"autorename":false,"mode":"overwrite","mute":false,"path":"${filename}","strict_conflict":false}`);
    xhr2.setRequestHeader("Content-Type", "application/octet-stream");

    xhr2.send(contents);
  }

  public getFile(filename: string): Promise<string> {
    if (!DropboxService.accessToken) {
      return new Promise((resolve, reject) => {
        resolve("");
      });
    }
    return new Promise((resolve, reject) => {
      let xhr2 = new XMLHttpRequest();
      xhr2.open("POST", "https://content.dropboxapi.com/2/files/download");
      xhr2.setRequestHeader("Authorization", "Bearer " + DropboxService.accessToken);
      xhr2.setRequestHeader("Dropbox-API-Arg", `{"path":"${filename}"}`);
      xhr2.setRequestHeader("Content-Type", "application/octet-stream");

      xhr2.onload = () => {
        resolve(xhr2.responseText);
      };

      xhr2.send();
    });
  }

  public listFilesInFolder(folderPath: string): Promise<listFolderResponse> {
    if (!DropboxService.accessToken) {
      return new Promise((resolve, reject) => {
        resolve(new listFolderResponse());
      });
    }
    return new Promise((resolve, reject) => {
      let xhr2 = new XMLHttpRequest();
      xhr2.open("POST", "https://api.dropboxapi.com/2/files/list_folder");
      xhr2.setRequestHeader("Authorization", "Bearer " + DropboxService.accessToken);
      xhr2.setRequestHeader("Content-Type", "application/json");

      xhr2.onload = () => {
        if (!xhr2.responseText) {
          reject(null);
          return;
        }

        const parsed = JSON.parse(xhr2.responseText);

        resolve(parsed);
      };

      xhr2.send(JSON.stringify({
        "include_deleted": false,
        "include_has_explicit_shared_members": false,
        "include_media_info": false,
        "include_mounted_folders": true,
        "include_non_downloadable_files": true,
        "path": folderPath,
        "recursive": true
      }));
    });
  }

  public getNotebooks(): Promise<AvailableNotebook[]> {
    return this.listFilesInFolder("").then(async (data) => {
      const availableNotebooks: AvailableNotebook[] = [];
      await Promise.all(data.entries.map(async (file) => {
        if (file.name === "meta.json") {
          return await this.getFile(file.path_display).then((data) => {
            const notebook = JSON.parse(data);
            const availableNotebook = new AvailableNotebook();
            availableNotebook.dropboxPath = file.path_display;
            availableNotebook.existsInOPFS = false;
            availableNotebook.metadata = notebook;
            availableNotebooks.push(availableNotebook);
          });
        }
      }));

      return availableNotebooks;
    });
  }

  public isConnected(): boolean {
    return DropboxService.accessToken !== null;
  }
}