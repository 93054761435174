
export default class SSOService
{
    static isLoadingAuth : boolean = false;

    public static hash(string : string) : Promise<string>
    {
        const utf8 = new TextEncoder().encode(string);
        return crypto.subtle.digest('SHA-256', utf8).then((hashBuffer) => {
            const hashArray = Array.from(new Uint8Array(hashBuffer));
            const hashHex = hashArray
            .map((bytes) => bytes.toString(16).padStart(2, '0'))
            .join('');
            return hashHex;
        });
    }

    public static generatePKCEHash(hexString : string) : string
    {
        var hashPwd = hexString;
        var encodedHash = SSOService.hexToBase64(hashPwd);
        return encodedHash;
    }

    public static hexToBase64(hexstring : string) : string {
        var match = hexstring.match(/\w{2}/g)
        if(!match)
        {
            throw new Error("Error");
        }
        return SSOService.URLBase64(match.map(function(a) {
            return String.fromCharCode(parseInt(a, 16));
        }).join(""));

    }

    public static URLBase64(string : string) : string {
        const base64 = btoa(string);
        const urlBase64 = base64.replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
        return urlBase64;
    }

    public static generateRandomString() : string {
        const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const length = 32;
        let str = '';
        for (let i = 0; i < length; i++) {
            str += chars.charAt(Math.floor(Math.random() * chars.length));
        }
    
        return str;
    }
}