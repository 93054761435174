import React, { useEffect, useState } from 'react';
import Note from '../models/note';
import NoteSidebar from './noteSidebar';

class NoteEditorProps {
  note: Note | null = null;
  notes: Array<Note> | null = null;
  onSave: Function | null = null;
}

function NoteEditor(props: NoteEditorProps) {
  const onSave = props.onSave;
  const note = props.note;

  const [title, setTitle] = useState(note?.title);
  const [editableText, setEditableText] = useState(note?.body);

  function noteTitleChanged(changeEvent: React.ChangeEvent<HTMLInputElement>) {
    if (!note) return;
    setTitle(changeEvent.target.value);
  };

  function noteBodyChanged(changeEvent: React.ChangeEvent<HTMLTextAreaElement>) {
    if (!note) return;
    setEditableText(changeEvent.target.value);
  };

  useEffect(() => {
    setTitle(props.note?.title);
    setEditableText(props.note?.body);
  }, [props.note]);

  function saveNote() {
    if (!note || !onSave || !editableText) return;
    note.title = title ? title : note.title;
    note.body = editableText;
    onSave(note.body);
  }

  return (
    <div style={{display: "flex", flexDirection: 'row', width: "100%"}}>
      <div style={{ width: "80%" }}>
        <input type="text" value={title} onChange={noteTitleChanged}></input>
        <textarea
          value={editableText}
          style={{ display: "block", width: "95%", height: "90%" }}
          onChange={noteBodyChanged}>
        </textarea>
        <button onClick={saveNote}>Save</button>
      </div>
      <div style={{width: "20%"}}>
        <NoteSidebar notes={props.notes} ></NoteSidebar>
      </div>
    </div>
  );
}

export default NoteEditor;
