

export default class NotebookMetadata{
    id: number = 0;
    name: string = "";
    syncsToDropbox: boolean = false;

    public NotebookMetadata()
    {
        this.id = Math.random() * 100000000000000000;
    }
}