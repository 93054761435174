import React, { useState, useEffect } from 'react';
import './App.css';
import SSOService from './services/ssoService';
import DropboxService from './services/dropboxService';
import CookieService from './services/cookieService';
import Note from './models/note';
import NoteEditor from './components/noteEditor';
import NotebookService from './services/notebookService';
import NotebookPicker from './components/notebookPicker';
import NotebookMetadata from './models/notebookMetadata';
import NoteList from './components/noteList';

function App() {
  const [link, setLink] = useState("#");
  const [fetchingCode, setFetchingCode] = useState(false);
  const [accessToken, setAccessToken] = useState("");
  const [activeNotebook, setActiveNotebook] = useState<NotebookMetadata | null>(null);
  const [activeNote, setActiveNote] = useState<Note | null>(null);
  const [notes, setNotes] = useState<Array<Note>>([]);

  const cookieToken = CookieService.getCookie("dropboxAccessToken");
  if(!accessToken && cookieToken){
    DropboxService.accessToken = cookieToken;
    setAccessToken(cookieToken);
  }

  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop as string),
  }) as any;
  let ssoCode = params.code;

  useEffect(() => {
    let input_str = "I am converting string to hashsdfgsdfgsdfgsdfgsdgf.";
    var encodedInputStr = SSOService.URLBase64(input_str);

    if(link === "#")
    {    
      SSOService.hash(encodedInputStr).then((hex) => {
        var encodedHash = SSOService.generatePKCEHash(hex);
        var ssoLink = DropboxService.getSSOLink(encodedHash);
        setLink(ssoLink);
      });
    }

    if(!fetchingCode && ssoCode){
      setFetchingCode(true)
      let xhr = new XMLHttpRequest();
      xhr.open("POST", "https://api.dropboxapi.com/oauth2/token");
      xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");

      xhr.onload = () => {
        let response = JSON.parse(xhr.responseText);
        if (!response.access_token) {
          return;
        }

        setAccessToken(response.access_token);
        const expiresInHours = response.expires_in / 60 / 60;
        CookieService.setCookie("dropboxAccessToken", response.access_token, expiresInHours);
        window.location.href = window.location.origin;
      };

      const clientId = DropboxService.getClientId();
      const redirectUri = DropboxService.getRedirectUri();
      let data = `grant_type=authorization_code&client_id=${clientId}&code=${ssoCode}&code_verifier=${encodedInputStr}&redirect_uri=${redirectUri}`;

      xhr.send(data);
    }
  }, [fetchingCode, link, ssoCode]);

  function saveFile(newBody : string) {
    if(!activeNote) return;
    activeNote.body = newBody;
    const notebookService = new NotebookService();
    notebookService.createNote(activeNote);
  }

  return (
    <div className="App" style={{display: "flex", flexFlow: "row"}}>
      <div style={{width: "200px"}}>
        <a href={link}>Dropbox</a>
        <br />
        <NotebookPicker setNotes={setNotes} setActiveNotebook={setActiveNotebook} />
        {activeNotebook ? <NoteList notes={notes} setNotes={setNotes} setActiveNote={setActiveNote} /> : ""}
      </div>
      {activeNote ? <NoteEditor onSave={saveFile} note={activeNote} notes={notes} /> : ""}
    </div>
  );
}

export default App;
